import { Link } from "gatsby"
import React from "react"

export const NewToSpring = () => (
  <div className="newToSpring has-background-light px-6 py-6 my-6">
    <img className="extra img-light" src="/img/extra/newToSpring.svg" />
    <img className="extra img-dark" src="/img/extra/newToSpring-dark.svg" />
    <div className="py-1 wrap">
      <h2 className="is-size-3 has-text-weight-medium mb-3">
        New to Spring? Start here.
      </h2>
      <div className="links-animate">
        With the Spring <Link to={"/quickstart"}>Quickstart guide</Link> you'll
        build a classic "Hello World!" endpoint.
      </div>
    </div>
  </div>
)
