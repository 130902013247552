import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { NewToSpring } from "../components/banner/NewToSpring"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { getGuides } from "../components/utils/Guide"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        youtube: string
        soundCloud: string
        guidesRef: Array<string>
      }
    }
    guides: {
      nodes: Array<{
        description: string
        name: string
        path: string
        title: string
        type: string
      }>
    }
    blog: {
      nodes: Array<{
        id: string
        fields: {
          path: string
          publishedAt: string
        }
        title: string
        author: {
          id: string
          title: string
        }
      }>
    }
  }
}

const YoutubeEmbed = ({ embedId }: any) => (
  <iframe
    width="100%"
    height="250"
    src={`https://www.youtube.com/embed?listType=user_uploads&list=${embedId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Spring"
  />
)

const SoundCloudEmbed = ({ embedId }: any) => (
  <iframe
    width="100%"
    height="400"
    scrolling="no"
    frameBorder="no"
    allow="autoplay"
    title="Audio player"
    src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/${embedId}&amp;color=%23ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;show_teaser=true&amp;visual=true`}
  ></iframe>
)

const Guide = ({ guide }: any) => (
  <article className="mb-5">
    <Link className="learn-guide" to={guide.path}>
      <h1 className="has-text-weight-medium">{guide.title}</h1>
      <p>{guide.description}</p>
    </Link>
  </article>
)

const Article = ({ article, isHighlight = false }: any) => (
  <article className="py-4">
    {isHighlight && <img className="mb-4" src={"/img/blog/main-1.jpeg"} alt="" />}
    <h1 className="is-size-4 has-text-weight-medium">
      <Link className="is-link" to={`/blog/${article.fields.path}`}>
        {article.title}
      </Link>
    </h1>
    <p className="pt-2">
      {article.author?.title} | {article.fields.publishedAt}
    </p>
  </article>
)

// markup
const LearnTemplate = (props: Props) => {
  const guides = getGuides(
    props.data.guides.nodes,
    props.data.page.frontmatter.guidesRef
  )
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className="learn"
    >
      <div className="extra">
        <div className="extra-1">
          <img className="img-light" src="/img/extra/overview.svg" alt="" />
          <img className="img-dark" src="/img/extra/overview-dark.svg" alt="" />
        </div>
      </div>
      <div className="container mb-6">
        <HTMLContent
          content={props.data.page.html}
          className={"markdown hero"}
        ></HTMLContent>
        <div className="columns mb-6">
          <div className="column">
            <Article isHighlight article={props.data.blog.nodes[0]} />
          </div>
          <div className="column is-4 pl-6 is-desktop">
            <Article article={props.data.blog.nodes[1]} />
            <Article article={props.data.blog.nodes[2]} />
            <Article article={props.data.blog.nodes[3]} />
            <div className="pt-5">
              <Link to={"/blog"} className="button is-spring">
                More blog posts
              </Link>
            </div>
          </div>
        </div>

        <NewToSpring />

        <div className="columns pb-6 pt-6">
          <div className="column is-4">
            <h2 className="has-big-border-dark-top is-size-4 has-text-weight-bold mb-5 pt-5">
              Popular guides
            </h2>
            {guides.map((guide: any, index: number) => (
              <Guide key={`guide-${index}`} guide={guide} />
            ))}
            <div className="pt-5">
              <Link to={"/guides"} className="button is-spring">
                More guides
              </Link>
            </div>
          </div>
          <div className="column is-4">
            <h2 className="has-big-border-dark-top is-size-4 has-text-weight-bold mb-5 pt-5">
              Latest videos
            </h2>
            <div>
              <YoutubeEmbed embedId={props.data.page.frontmatter.youtube} />
            </div>
            <div className="pt-5">
              <a
                href={`https://www.youtube.com/user/${props.data.page.frontmatter.youtube}`}
                className="button is-spring"
              >
                More videos
              </a>
            </div>
          </div>
          <div className="column is-4">
            <h2 className="has-big-border-dark-top is-size-4 has-text-weight-bold mb-5 pt-5">
              Latest podcasts
            </h2>
            <div>
              <SoundCloudEmbed
                embedId={props.data.page.frontmatter.soundCloud}
              />
            </div>
            <div className="pt-5">
              <a
                href={`https://soundcloud.com/a-bootiful-podcast`}
                className="button is-spring"
              >
                More podcasts
              </a>
            </div>
          </div>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default LearnTemplate

// graphQL queries
export const pageQuery = graphql`
  query LearnTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        youtube
        soundCloud
        guidesRef
      }
    }
    guides: allApiGuide {
      nodes {
        description
        name
        title
        type
        path
      }
    }
    blog: allContentfulBlogPost(
      sort: { order: DESC, fields: fields___publishedAt }
      limit: 4
    ) {
      nodes {
        id
        fields {
          path
          publishedAt(formatString: "MMMM DD")
        }
        title
        author {
          id
          title
        }
      }
    }
  }
`
